import React from 'react';

// Images
import orchester1 from "../../assets/img/alejandro-loguercio/classical-music.jpg";
import orchester2 from "../../assets/img/alejandro-loguercio/alejandro-loguercio-violin.jpg";

// Components
import OrchesterImg from "./OrchesterImg";

// Styles
import { Container } from "../../styles/globalStyles";
import { FlexDiv, OrchesterDiv, Intro } from "../../styles/components/orchesterStyles";

const ClassText = ({content, language, transition}) => {

  // JSON data
  const data = content[language];

  return (
    <Container>
      <FlexDiv>
        <OrchesterDiv
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{transition, duration: 0.75, delay: 0.5}}
        >
          <OrchesterImg transition={transition} img={orchester1} imgAlign="left" bgPos="40%" />
        </OrchesterDiv>
        <OrchesterDiv
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{transition, duration: 1, delay: 1}}
        >
          <OrchesterImg transition={transition} img={orchester2} imgAlign="right" bgPos="80%"/>
        </OrchesterDiv>
      </FlexDiv>
      <Intro
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}
        transition={{transition, duration: 1, delay: 1.25}}
      >
        <h1>{data.mainTitle1}</h1>
        {data.mainText.map((item, index) => (
          <p key={index}>{item.para}</p>
        ))}
      </Intro>
    </Container>
  )
}

export default ClassText
