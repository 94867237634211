import React, { useEffect } from 'react'

import quadrat from "../../assets/video/quadrat.mp4";
import img from "../../assets/img/alejandro-loguercio/Alejandro Loguercio_small-5.jpg";

// Scroll Behaviour
import { useInView } from "react-intersection-observer"
import { useAnimation } from "framer-motion"

// Motion
import { scrollView, transition } from "../../animation/classes";

// Styles
import { Container } from "../../styles/globalStyles";
import { Video } from "../../styles/components/orchesterStyles";

const ClassVideo = () => {

  const animation = useAnimation();
  const [contentRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-150px",
  });

  useEffect(() => {
    if (inView) {
      animation.start("visible")
    }
  }, [animation, inView]);

  return (
    <Container
      ref={contentRef}
      animate={animation}
      initial="hidden"
      variants={scrollView}
      exit="hidden"
      transition={transition}
    >
    <Video controls>
      <source src={quadrat} type="video/mp4" />
      Your browser does not support the video tag.
    </Video>
      
    </Container>
  )
}

export default ClassVideo
