import React, { useEffect, useContext } from 'react';

// Components 
import OrchesterText from "../components/orchester/OrchesterText";
import OrchesterPrices from "../components/orchester/OrchesterPrices";
import OrchesterVideo from "../components/orchester/OrchesterVideo";
import PanelTransition from "../components/panel/PanelTransition";

// Styles
import { Page } from "../styles/components/classesStyles";

// Animation
import { transition } from "../animation/biography";

// Content
import Content from "../content/orchester.json";

// Context
import LanguageContext from "../context/LanguageContext";
import ClassFootNote from '../components/classes/ClassFootNote';

const Orchester = () => {

  // App language
  const [language, setLanguage] = useContext(LanguageContext);

  // Set language to German
  useEffect(() => {
    localStorage.setItem("AL - Lang", "de");
    setLanguage("de");
  }, [setLanguage]);

  return (
    <Page>
      <OrchesterText content={Content} language={language} transition={transition} />
      <OrchesterVideo />
      <OrchesterPrices content={Content} language={language} />
      <ClassFootNote content={Content} language={language} />
      <PanelTransition />
    </Page>
  )
}

export default Orchester
